var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        justify: "center",
        value: _vm.value,
        persistent: "",
        "max-width": "500"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "#fafafa" } },
        [
          _c(
            "v-alert",
            {
              staticClass: "rounded-0 white--text",
              attrs: { dense: "", color: "primary" }
            },
            [
              _c(
                "v-card-title",
                { staticClass: "text-capitalize" },
                [
                  _c(
                    "v-icon",
                    { attrs: { left: "", size: "50", color: "white" } },
                    [_vm._v("mdi-help-rhombus")]
                  ),
                  _vm._v(
                    " " +
                      _vm._s(_vm.type) +
                      " " +
                      _vm._s(_vm.invoice ? "Invoice" : "Invoice Batch") +
                      "? "
                  ),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-text", { staticClass: "px-12" }, [
            _vm._v(
              " Are you sure you want to " +
                _vm._s(_vm.type) +
                " " +
                _vm._s(_vm.invoice ? "invoice" : "invoice batch") +
                "? "
            )
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("update:value", false)
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "grey lighten-2 ml-2",
                  on: { click: _vm.confirm }
                },
                [_vm._v("Confirm")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }