<template>
  <v-dialog justify="center" :value="value" persistent max-width="500">
    <v-card color="#fafafa">
      <v-alert dense color="primary" class="rounded-0 white--text">
        <v-card-title class="text-capitalize">
          <v-icon left size="50" color="white">mdi-help-rhombus</v-icon>
          {{ type }} {{ invoice ? 'Invoice' : 'Invoice Batch' }}?
          <v-spacer />
        </v-card-title>
      </v-alert>
      <v-card-text class="px-12">
        Are you sure you want to {{ type }}
        {{ invoice ? 'invoice' : 'invoice batch' }}?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="$emit('update:value', false)">Cancel</v-btn>
        <v-btn class="grey lighten-2 ml-2" @click="confirm">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationModal',

  props: ['value', 'type', 'selectedBatch'],

  computed: {
    invoice() {
      return 'invoice_number' in this.selectedBatch
    },
  },

  methods: {
    confirm() {
      switch (this.type) {
        case 'restart':
          this.$emit('restart', this.selectedBatch)
          break
        case 'delete':
          this.invoice
            ? this.$emit('deleteInvoice', this.selectedBatch)
            : this.$emit('deleteBatch', this.selectedBatch)
          break
        case 'post':
          this.$emit('post', this.selectedBatch)
          break
        case 'send':
          console.log('Send Clicked')
          this.$emit('send', this.selectedBatch)
          break
      }
      this.$emit('update:value', false)
    },
  },
}
</script>
